import PropTypes from "prop-types"
import React from "react"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Menu = ({ menuClassName, itemClassName }) => {
  const intl = useIntl()

  return (
    <div className="md:col-span-2 py-5 hidden md:block" id="menu">
      <ul className="flex flex-wrap md:justify-end">
        <li className="text-secondary-100 py-2 w-full text-center md:px-2 md:w-auto md:inline-block hover:text-secondary-300">
          <Link to={intl.formatMessage({ id: "home_url" })}>
            <FormattedMessage id="home_title" />
          </Link>
        </li>
        <li className="text-secondary-100 py-2 w-full text-center md:px-2 md:w-auto md:inline-block hover:text-secondary-300">
          <Link to={intl.formatMessage({ id: "about_url" })}>
            <FormattedMessage id="about_title" />
          </Link>
        </li>
        <li className="text-secondary-100 py-2 w-full text-center md:px-2 md:w-auto md:inline-block hover:text-secondary-300">
          <Link to={intl.formatMessage({ id: "products_url" })}>
            <FormattedMessage id="products_title" />
          </Link>
        </li>
        <li className="text-secondary-100 py-2 w-full text-center md:px-2 md:w-auto md:inline-block hover:text-secondary-300">
          <Link to={intl.formatMessage({ id: "contact_url" })}>
            <FormattedMessage id="contact_title" />
          </Link>
        </li>
      </ul>
    </div>
  )
}

Menu.propTypes = {
  menuClassName: PropTypes.string,
  itemClassName: PropTypes.string,
}

Menu.defaultProps = {
  menuClassName: `flex items-stretch md:items-baseline`,
  itemClassName: `block py-2 px-4 text-gray-600 hover:text-gray-700`,
}

export default Menu
