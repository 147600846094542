import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "../components/menu"
import Logo from "../images/logo.png"

const Header = ({ siteTitle }) => {
  const burgerClick = e => {
    const menu = document.querySelector("#menu")

    if (menu.classList.contains("hidden")) {
      menu.classList.remove("hidden")
    } else {
      menu.classList.add("hidden")
    }
  }
  return (
    <header className="w-full  bg-primary-800 text-white ">
      <div className="w-full bg-primary-900 text-white  py-1 text-xs font-light  ">
        <div className="container mx-auto flex flex-row items-center justify-between">
          <div className="flex flex-row items-center px-5">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              ></path>
            </svg>
            <span className="ml-1">+90 258 3718563</span>
            <svg
              class="w-6 h-6 ml-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
              ></path>
            </svg>
            <span className="ml-1">
              <a href="mailto:bilgi@mrctekstil.com">bilgi@mrctekstil.com</a>
            </span>
          </div>
          <div className="px-5">
            <Link to="/">Türkçe</Link> | <Link to="/en/">English</Link>
          </div>
        </div>
      </div>
      <nav className="container mx-auto grid md:grid-cols-3">
        <div className="md:col-span-1 flex justify-between items-center md:justify-start py-5 px-5">
          <span className="text-3xl text-gray-700">
            <Link to="/">
              <img className="w-64 h-auto" src={Logo} alt={siteTitle} />{" "}
            </Link>
          </span>

          <div
            className="px-4 cursor-pointer md:hidden"
            id="burger"
            onClick={burgerClick}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </div>
        </div>

        <Menu />
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
